export const aboutLitt: Record<string, Record<string, string>> = {
  fr: {
    aboutTitle: "A propos",
    partners: "Partenaires",
    funding: "Financement",
    consortium: "Consortium",
    scholarM: "Enseignant chercheur",
    postPHDM: "Post-doctorant",
    PHDStudentM: "Doctorant",
    studyEngineerM: "Ingénieur d'études",
    researchEngineerM: "Ingénieur de recherche",
    researchEngineerF: "Ingénieure de recherche",
    scholarF: "Enseignante chercheure",
    postPHDF: "Post-doctorante",
    PHDStudentF: "Doctorante",
    studyEngineerF: "Ingénieure d'études",
    igreM: "Ingénieur de recherche en éducation",
    igreF: "Ingénieure de recherche en éducation",
    introText:
      "La plateforme numérique ECC’IPE vise à développer par" +
      " l’entraînement les compétences à travailler en équipe, " +
      "une des compétences sociales ou transversales (« soft skills»)" +
      " clé pour favoriser l’employabilité, l’insertion professionnelle des jeunes, " +
      "et le maintien dans l’emploi des salariés." +
      " Cette plateforme est utilisable en présentiel et/ou à distance" +
      " et s’adresse à des publics variés.  ",
    partnersText:
      "La plateforme numérique ECC’IPE a été conçue et développée par " +
      "les équipes de recherches du Laboratoire de Psychologie Cognition, " +
      "Comportement, Communication (LP3C, Université Rennes 2) et du Laboratoire " +
      "d'Informatique, de Modélisation et d'Optimisation des Systèmes (LIMOS, " +
      "Université de Clermont Auvergne). ",
    fundingText:
      "Ce projet, initié par la Mission Monteil, " +
      "a été financé dans le cadre de France 2030 pour l’action" +
      " « Innovation numérique pour l’excellence éducative » du Programme " +
      "d’investissements d’avenir 2.  ",
    moreButtonText: "Plus d’informations",
    moreButtonLink: "https://www.lp3c.fr/eccipe/",
    contact: "nicolas.michinov[at]univ-rennes2.fr",

    lp3cFullName:
      "Laboratoire de Psychologie : Cognition, Comportement, Communication",
    lp3cFullSummary:
      "Les recherches du Laboratoire de Psychologie, Cognition, Comportement, " +
      "Communication (LP3C) s’inscrivent dans les domaines de la psychologie " +
      "expérimentale, de la psychologie sociale, de la psychologie cognitive, " +
      "de la psychologie différentielle, de la psychologie ergonomique, de la " +
      "psychologie du développement et de l’éducation, autour de plusieurs axes " +
      "de recherche.",
    lp3cWebsite: "https://www.lp3c.fr/",

    limosFullName:
      "Laboratoire d'Informatique, de Modélisation et d'Optimisation des Systèmes ",
    limosSummary:
      "Le Laboratoire d'Informatique, de Modélisation et d'Optimisation des Systèmes " +
      "(LIMOS) est une Unité Mixte de Recherche (UMR 6158) en informatique, et plus " +
      "généralement en Sciences et Technologies de l'Information et de la " +
      "Communication (STIC). ",
    limosWebsite: "https://limos.fr/",

    // fundingText: "Innovation Numérique pour l'Excellence Éducative",
  },
  en: {
    aboutTitle: "About",
    partners: "Partner",
    funding: "Funding",
    consortium: "Consortium",
    scholarM: "Scholar",
    postPHDM: "Post-doctoral fellow",
    PHDStudentM: "PHD",
    studyEngineerM: "Study engineer",
    researchEngineerM: "Research engineer",
    researchEngineerF: "Research engineer",
    scholarF: "Scholar",
    postPHDF: "Post-doctoral fellow",
    PHDStudentF: "PHD",
    studyEngineerF: "Study engineer",
    introText:
      "La plateforme numérique ECC’IPE vise à développer par" +
      " l’entraînement les compétences à travailler en équipe, " +
      "une des compétences sociales ou transversales (« soft skills»)" +
      " clé pour favoriser l’employabilité, l’insertion professionnelle des jeunes, " +
      "et le maintien dans l’emploi des salariés." +
      " Cette plateforme est utilisable en présentiel et/ou à distance" +
      " et s’adresse à des publics variés.  ",
    partnersText:
      "La plateforme numérique ECC’IPE a été conçue et développée par " +
      "les équipes de recherches du Laboratoire de Psychologie Cognition, " +
      "Comportement, Communication (LP3C, Université Rennes 2) et du Laboratoire " +
      "d'Informatique, de Modélisation et d'Optimisation des Systèmes (LIMOS, " +
      "Université de Clermont Auvergne). ",
    fundingText:
      "Ce projet, initié par la Mission Monteil, " +
      "a été financé dans le cadre de France 2030 pour l’action" +
      " « Innovation numérique pour l’excellence éducative » du Programme " +
      "d’investissements d’avenir 2.  ",
    moreButtonText: "Plus d’informations",
    moreButtonLink: "https://www.lp3c.fr/eccipe/",
    contact: "nicolas.michinov[at]univ-rennes2.fr",

    lp3cFullName: "Psychology Laboratory: Cognition, Behavior, Communication ",
    lp3cFullSummary:
      "The LP3C (Psychology Laboratory: Cognition, Behaviour and Communication) " +
      "performs experimental research in psychology, including the fields of " +
      "social psychology, cognitive psychology, ergonomics psychology, " +
      "differential psychology, developmental psychology and education, " +
      "with four major research areas.",
    lp3cWebsite: "https://www.lp3c.fr/en/presentation/",

    limosFullName:
      "Laboratory of Informatics, Modelling and Optimization of the Systems",
    limosSummary:
      "The Laboratory of Informatics, Modelling and Optimization of the Systems " +
      "(LIMOS) is a Mixed Unit of Research (UMR 6158) in computing, and more " +
      "generally in Sciences and Technologies of information and the Communication " +
      "( STIC). ",
    limosWebsite: "https://limos.fr/",

    // fundingText: "Software Innovation for Educational Excellence",
  },
};

export const partners: Record<
  string,
  {
    title: string;
    logo: string;
    subtitle: string;
    divider?: boolean;
    website: string;
  }[]
> = {
  fr: [
    {
      title: "LP3C",
      logo: "lp3c_logo.svg",
      subtitle:
        "Le LP3C, Laboratoire de Psychologie : Cognition, Comportement, Communication, " +
        "est un institut de recherche dont un des axes concerne les performances et " +
        "apprentissages des individus et " +
        "des équipes notamment dans les environnements numériques " +
        "(Axe 3 : « Performances, socio-performances et apprentissages »).",
      website: "https://www.lp3c.fr",
    },
    {
      title: "LIMOS",
      logo: "limos_logo.png",
      subtitle:
        "Le LIMOS, Laboratoire d’Informatique, de Modélisation et d’Optimisation des Systèmes," +
        " est une unité mixte de recherche ayant pour tutelles le CNRS, " +
        "l’Université Clermont Auvergne et l’école des Mines de Saint-Etienne. " +
        "L’équipe DSI (Données, Services, Intelligence) du LIMOS, impliquée dans ce projet, " +
        "développe des activités de recherche dans le domaine de la gestion de " +
        "grandes masses données et de connaissances, la qualité des données ainsi que " +
        "l’Intelligence artificielle et l’apprentissage automatique à partir des données.",
      website: "https://limos.fr",
    },
    {
      title: "UCA",
      logo: "uca_logo.png",
      subtitle: "Université Clermont Auvergne",
      website: "https://uca.fr",
    },
    {
      title: "Rennes 2",
      logo: "rennes2_logo.png",
      subtitle: "Université Rennes 2 ",
      website: "https://www.univ-rennes2.fr/",
    },
    {
      title: "Lycée Coëtlogon - Rennes",
      logo: "coetlogon-logo.jpg",
      subtitle:
        "Lycée d'enseignement professionnel. Nous avons pu conduire différentes études auprès du personnel d'enseignement et des élèves.",
      website: "https://lycee-coetlogon.ac-rennes.fr/",
    },
  ],
  en: [
    {
      title: "LP3C",
      logo: "lp3c_logo.svg",
      subtitle: "",
      website: "https://www.lp3c.fr",
    },
    {
      title: "LIMOS",
      subtitle: "",
      logo: "limos_logo.png",
      website: "https://limos.fr",
    },
    {
      title: "UCA",
      logo: "uca_logo.png",
      subtitle: "",
      website: "https://uca.fr",
    },
    {
      title: "Rennes 2",
      logo: "rennes2_logo.png",
      subtitle: "",
      website: "https://www.univ-rennes2.fr/",
    },
    {
      title: "Lycée Coëtlogon - Rennes",
      logo: "coetlogon-logo.jpg",
      subtitle: "",
      website: "https://lycee-coetlogon.ac-rennes.fr/",
    },
  ],
};

export const limosMembers: Record<
  string,
  {
    firstName: string;
    lastName: string;
    position: string;
    projectRole: string;
  }[]
> = {
  fr: [
    {
      firstName: "Marinette",
      lastName: "BOUET",
      position: aboutLitt["fr"]["scholarF"],
      projectRole: "Responsable projet et thèse",
    },
    {
      firstName: "Farouk",
      lastName: "TOUMANI",
      position: aboutLitt["fr"]["scholarM"],
      projectRole: "Responsable projet et thèse",
    },
    {
      firstName: "Zineddine",
      lastName: "ZAHOUANI",
      position: aboutLitt["fr"]["PHDStudentM"],
      projectRole:
        "Raisonnement hybride et recommandations dynamiques pour optimiser la dynamique de groupe.",
    },
    {
      firstName: "Stéphane",
      lastName: "GUILLET",
      position: aboutLitt["fr"]["studyEngineerM"],
      projectRole: "Développeur",
    },
    {
      firstName: "Vincent",
      lastName: "MAZENOD",
      position: aboutLitt["fr"]["researchEngineerM"],
      projectRole: "Gestion de projet",
    },
    {
      firstName: "Lise",
      lastName: "DEMOURGUES",
      position: aboutLitt["fr"]["studyEngineerF"],
      projectRole: "Développeure",
    },
  ],
  en: [
    {
      firstName: "Marinette",
      lastName: "BOUET",
      position: aboutLitt["en"]["scholarF"],
      projectRole: "Project and thesis supervisor",
    },
    {
      firstName: "Farouk",
      lastName: "TOUMANI",
      position: aboutLitt["en"]["scholarM"],
      projectRole: "Project and thesis supervisor",
    },
    {
      firstName: "Zineddine",
      lastName: "ZAHOUANI",
      position: aboutLitt["en"]["PHDStudentM"],
      projectRole:
        "Hybrid reasoning and dynamic recommendations to optimize group dynamics.",
    },
    {
      firstName: "Stéphane",
      lastName: "GUILLET",
      position: aboutLitt["en"]["studyEngineerM"],
      projectRole: "Developper",
    },
    {
      firstName: "Vincent",
      lastName: "MAZENOD",
      position: aboutLitt["en"]["researchEngineerM"],
      projectRole: "Project coordinator",
    },
    {
      firstName: "Lise",
      lastName: "DEMOURGUES",
      position: aboutLitt["en"]["studyEngineerF"],
      projectRole: "Developper",
    },
  ],
};

export const lp3cMembers: Record<
  string,
  {
    firstName: string;
    lastName: string;
    position: string;
    projectRole: string;
  }[]
> = {
  fr: [
    {
      firstName: "Estelle",
      lastName: "MICHINOV",
      position: aboutLitt["fr"]["scholarF"],
      projectRole: "Responsable projet et thèse",
    },
    {
      firstName: "Nicolas",
      lastName: "MICHINOV",
      position: aboutLitt["fr"]["scholarM"],
      projectRole: "Responsable projet et thèse",
    },
    {
      firstName: "Eric",
      lastName: "JAMET",
      position: aboutLitt["fr"]["scholarM"],
      projectRole: "Responsable projet et thèse",
    },
    {
      firstName: "Anthony",
      lastName: "CHERBONNIER",
      position: aboutLitt["fr"]["igreM"],
      projectRole: "Gestion de projet",
    },
    {
      firstName: "Albane",
      lastName: "PECHARD",
      position: aboutLitt["fr"]["PHDStudentF"],
      projectRole:
        "Les effets des différents types de feedback lors d’un entraînement au travail en équipe au sein d’un environnement numérique.",
    },
    {
      firstName: "Sacha",
      lastName: "DROUET",
      position: aboutLitt["fr"]["PHDStudentM"],
      projectRole:
        "Les effets des types de conseils lors d’un entraînement au " +
        "travail en équipe à partir d’un environnement numérique.",
    },
    {
      firstName: "Brivael",
      lastName: "HEMON",
      position: aboutLitt["fr"]["postPHDM"],
      projectRole: "",
    },
    {
      firstName: "Ewen",
      lastName: "OSSELAR",
      position: aboutLitt["fr"]["studyEngineerM"],
      projectRole: "Ergonomie",
    },
  ],
  en: [
    {
      firstName: "Estelle",
      lastName: "MICHINOV",
      position: aboutLitt["en"]["scholarF"],
      projectRole: "Project and thesis supervisor",
    },
    {
      firstName: "Nicolas",
      lastName: "MICHINOV",
      position: aboutLitt["en"]["scholarM"],
      projectRole: "Project and thesis supervisor",
    },
    {
      firstName: "Eric",
      lastName: "JAMET",
      position: aboutLitt["en"]["scholarM"],
      projectRole: "Project and thesis supervisor",
    },
    {
      firstName: "Anthony",
      lastName: "CHERBONNIER",
      position: aboutLitt["en"]["igreM"],
      projectRole: "Project coordinator",
    },
    {
      firstName: "Albane",
      lastName: "PECHARD",
      position: aboutLitt["en"]["PHDStudentF"],
      projectRole:
        "The impact of various feedback types on teamwork training in a digitized environment.",
    },
    {
      firstName: "Sacha",
      lastName: "DROUET",
      position: aboutLitt["en"]["PHDStudentM"],
      projectRole:
        "The influence of different advice methods on teamwork training in a digitized environment.",
    },
    {
      firstName: "Brivael",
      lastName: "HEMON",
      position: aboutLitt["en"]["postPHDM"],
      projectRole: "",
    },
    {
      firstName: "Ewen",
      lastName: "OSSELAR",
      position: aboutLitt["en"]["studyEngineerM"],
      projectRole: "Ergonomy",
    },
  ],
};


import { Vue, Component } from "vue-property-decorator";
import {
  aboutLitt,
  partners,
  limosMembers as limos,
  lp3cMembers as lp3c,
} from "@/utils/literals/about";
import { assetsUrl } from "@/env";
import { alphabeticalSort } from "@/utils";

@Component({})
export default class AboutPage extends Vue {
  lang = "fr";
  tab = 0;

  getUF(key: string): string {
    return aboutLitt[this.lang][key] || "";
  }

  get partners(): {
    title: string;
    logo: string;
    subtitle: string;
    divider?: boolean;
    website: string;
  }[] {
    return partners[this.lang];
  }
  /**
   * Retrieves image relative path
   * @param slug
   */
  getImageSrc(slug: string): string {
    return `${assetsUrl}/${slug}`;
  }

  get limosMembers(): {
    firstName: string;
    lastName: string;
    position: string;
    projectRole: string;
  }[] {
    return limos[this.lang].sort((a, b) =>
      alphabeticalSort(a.lastName, b.lastName)
    );
  }

  get lp3cMembers(): {
    firstName: string;
    lastName: string;
    position: string;
    projectRole: string;
  }[] {
    return lp3c[this.lang].sort((a, b) =>
      alphabeticalSort(a.lastName, b.lastName)
    );
  }
}
